import { MouseEvent, useMemo } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { Box, Button, Flex, Heading, Progress, Skeleton, Stack, Tag, Text, useDisclosure } from '@chakra-ui/react';
import { Difficulty1Icon, Difficulty2Icon, Difficulty3Icon, TimeIcon } from '@udacity/chakra-uds-icons';
import { BreakpointValue, focusStyles } from '@udacity/chakra-uds-theme';

import { ButtonLink } from '~/components/button-link';
import { PlayButton } from '~/components/play-button';
import { Rating } from '~/components/rating';
import { SubscriptionPausedModal } from '~/features/dashboard/components/subscription-paused-modal';
import { publicEnv } from '~/features/environment/public';
import { useClientExperiment } from '~/features/experiments/hooks/use-client-experiment';
import { FavoriteButton } from '~/features/favorites/components/favorite-button';
import { i18n } from '~/features/internationalization/internationalization';
import { Subscription } from '~/features/payment/models/subscription';
import { useEnrollments } from '~/features/program/hooks/use-enrollments';
import { useEnrollmentsProgress } from '~/features/program/hooks/use-enrollments-progress';
import { useUser } from '~/features/user/hooks/use-user';
import { useClientRender } from '~/hooks/use-client-render';
import { useI18n } from '~/hooks/use-i18n';

import { CatalogCardItem } from '../models/catalog-card-item';
import { CatalogItem } from '../models/catalog-item';
import { CatalogSearchItem } from '../models/catalog-search-result';

type CatalogCardWithCatalogItem = {
  catalogItem: CatalogItem | CatalogCardItem | CatalogSearchItem;
  basicItem?: never;
  width?: string | BreakpointValue<string>;
  minWidth?: string | BreakpointValue<string>;
  grow?: boolean;
  priority?: boolean;
  imageQuality?: number;
  subscription?: Subscription | null;
  hasIncompleteRequiredAssessment?: boolean;
  learningPlanAssessmentStepId?: string;
  onClick?: (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void;
};

type CatalogCardWithBasicItem = {
  catalogItem?: never;
  basicItem: {
    title: string;
    key: string;
  };
  width?: string | BreakpointValue<string>;
  minWidth?: string | BreakpointValue<string>;
  priority?: boolean;
  grow?: boolean;
  subscription?: never;
  hasIncompleteRequiredAssessment?: boolean;
  learningPlanAssessmentStepId?: string;
  onClick?: (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void;
};

export function CatalogCard(props: CatalogCardWithCatalogItem | CatalogCardWithBasicItem) {
  const { t } = useI18n();
  const router = useRouter();
  const hasCatalogItem = isCatalogCardWithCatalogItem(props);
  const { userId } = useUser();
  const { enrollment, enrollmentsIsLoading } = useEnrollments(
    hasCatalogItem ? props.catalogItem.key : props.basicItem.key
  );
  const hasReviews = hasCatalogItem ? props.catalogItem.reviewSummary.numberOfReviews > 0 : false;
  const clientRender = useClientRender();
  const { enrollmentsProgress } = useEnrollmentsProgress();
  const experiment = useClientExperiment('ab_product_card_redesign');

  const programProgress = enrollmentsProgress?.programs.find(
    (program) => program.key === (hasCatalogItem ? props.catalogItem.key : props.basicItem.key)
  );

  const isReadyForGraduation = enrollment && enrollment.isReadyForGraduation && !enrollment.isGraduated;

  const { subscription, hasIncompleteRequiredAssessment, learningPlanAssessmentStepId } = props;
  const isConsumerSubscriptionPaused = subscription?.status === 'paused' && enrollment?.state === 'PAUSED';
  const formattedResumeDate = subscription?.resumeAt
    ? new Date(subscription?.resumeAt).toLocaleDateString(i18n.language)
    : '';
  const { isOpen: isPausedModalOpen, onOpen: openPausedModal, onClose: closePausedModal } = useDisclosure();

  const fallbackImage = '/images/program-fallbacks/8.png';
  const enterpriseData = hasCatalogItem && 'enterprise' in props.catalogItem && props.catalogItem.enterprise;
  const isEnrollable = enterpriseData ? enterpriseData?.enrollable : false;

  const url = useMemo(() => {
    if (enrollmentsIsLoading) return undefined;
    if (enrollment) return `/enrollment/${enrollment.key}`;
    if (hasCatalogItem) {
      let pathname = `/course/${props.catalogItem.slug}`;
      if (router.query.companySlug) {
        pathname += `?companySlug=${router.query.companySlug}`;
      }
      return pathname;
    }
  }, [enrollment, enrollmentsIsLoading, hasCatalogItem, props.catalogItem, router.query.companySlug]);

  function legacyHandleCardClick(event: MouseEvent<HTMLDivElement>) {
    event.preventDefault();
    props.onClick?.(event);
    if (url) {
      router.push(url);
    }
  }

  if (experiment.isLoading) {
    return <CatalogCardSkeleton width={props.width} />;
  }

  if (experiment.variant === 'variant_b') {
    return (
      <Box
        as='article'
        bg='white'
        border='1px solid'
        borderColor='gray.200'
        borderRadius='6px'
        flex={props.grow ? { base: 'unset', xl: 1 } : 'unset'}
        flexShrink={0}
        height='292px'
        minWidth={props.minWidth}
        p={1}
        position='relative'
        role='group'
        transition='all 0.3s ease-in-out'
        width={props.width}
        _hover={{
          boxShadow: 'lg',
        }}
        onClick={props.onClick}
      >
        <Flex flexDir='column' minHeight='100%'>
          <Flex
            borderTopRadius='base'
            padding='8px'
            {...(focusStyles('blue.500') as any)}
            flex='0 0 100px'
            height='100px'
            overflow='hidden'
          >
            <Box
              bgGradient='linear(to-b, transparent, white, transparent)'
              bottom={0}
              height='120%'
              mixBlendMode='soft-light'
              opacity={0.3}
              pointerEvents='none'
              position='absolute'
              right={0}
              transform={`translateX(50%) translateY(100%) rotate(-20deg)`}
              transition='transform 0.3s ease-in-out'
              width='200%'
              zIndex={1}
              _groupHover={{
                transform: `translateX(10%) translateY(-10%) rotate(-20deg)`,
              }}
            />
            {hasCatalogItem && props.catalogItem.image && typeof props.catalogItem.image === 'string' && (
              <Image
                alt=''
                priority={Boolean(props.priority)}
                quality={100}
                sizes='442px'
                src={props.catalogItem.image}
                style={{ objectFit: 'cover', borderRadius: '4px' }}
                fill
              />
            )}
            {!props.catalogItem?.image && (
              <Image
                alt=''
                priority={Boolean(props.priority)}
                quality={100}
                sizes='442px'
                src={fallbackImage}
                style={{ objectFit: 'cover', borderRadius: '4px' }}
                fill
              />
            )}
          </Flex>

          <Flex flex={1} flexDir='column' marginBottom='-4px' marginLeft='-4px' marginRight='-4px' p={3}>
            <Heading
              as='a'
              color='black'
              fontFamily='body'
              fontSize='md'
              fontWeight='600'
              href={url}
              lineHeight='1.375rem'
              noOfLines={!enrollment && hasCatalogItem && hasReviews ? 3 : 4}
              sx={{
                '&::before': {
                  display: 'block',
                  content: '""',
                  position: 'absolute',
                  inset: 0,
                  zIndex: 1,
                },
              }}
            >
              {hasCatalogItem ? props.catalogItem.title : props.basicItem.title}
            </Heading>

            {!enrollment &&
              hasCatalogItem &&
              (hasReviews || props.catalogItem.duration || props.catalogItem.difficultyLevel) && (
                <Stack marginTop='auto' spacing='12px'>
                  {hasReviews && (
                    <Flex gap='4px'>
                      <Rating
                        color='feedback.warning'
                        rating={props.catalogItem.reviewSummary.starsAverage}
                        size='sm'
                      />
                      <Text color='gray.700' fontWeight='600' size='caption'>
                        ({props.catalogItem.reviewSummary.numberOfReviews})
                      </Text>
                    </Flex>
                  )}
                  <Flex borderTop='1px solid' borderTopColor='gray.100' flexDir='column' pt={3}>
                    <Flex alignItems='center' color='gray.700' flexBasis='18px' fontWeight='500' gap={1}>
                      {props.catalogItem.duration && (
                        <>
                          <TimeIcon />
                          <Text size='caption' textTransform='capitalize'>
                            {props.catalogItem.duration}
                          </Text>
                        </>
                      )}
                    </Flex>
                    <Flex alignItems='center' color='gray.700' flexBasis='18px' fontWeight='500' gap={1}>
                      {props.catalogItem.difficultyLevel && (
                        <>
                          {(props.catalogItem.difficultyLevel === 'Beginner' ||
                            props.catalogItem.difficultyLevel === 'Discovery' ||
                            props.catalogItem.difficultyLevel === 'Fluency') && <Difficulty1Icon />}
                          {props.catalogItem.difficultyLevel === 'Intermediate' && <Difficulty2Icon />}
                          {props.catalogItem.difficultyLevel === 'Advanced' && <Difficulty3Icon />}
                          <Text size='caption'>{props.catalogItem.difficultyLevel}</Text>
                        </>
                      )}
                    </Flex>
                  </Flex>
                </Stack>
              )}

            {enrollment && (
              <Flex flex='1' flexDir='column'>
                <Flex alignItems='flex-end' flex='1' gap='8px' marginTop='auto'>
                  {!isReadyForGraduation && isConsumerSubscriptionPaused && (
                    <Box position='relative' zIndex={2}>
                      <SubscriptionPausedModal
                        isOpen={isPausedModalOpen}
                        paymentDate={formattedResumeDate}
                        onClose={closePausedModal}
                      />
                      <PlayButton
                        ariaLabel={t('settings.subscription.resumeSubscription')!}
                        overrideColor='gray.500'
                        onClick={openPausedModal}
                      />
                    </Box>
                  )}
                  {!isReadyForGraduation && !isConsumerSubscriptionPaused && (
                    <Box position='relative' zIndex={2}>
                      <PlayButton
                        href={`${enrollment.classroomLink}/resume-learning`}
                        overrideColor='blue.500'
                        ariaLabel={
                          t('program.continueProgram', {
                            programName: hasCatalogItem ? props.catalogItem.title : props.basicItem.title,
                          })!
                        }
                      />
                    </Box>
                  )}
                  <Stack alignItems='flex-start' flex={1} spacing='4px' width='100%'>
                    <Progress
                      aria-label={t('program.progressIs', { percent: programProgress?.completion ?? 0 })!}
                      bg='gray.200'
                      data-testid='catalog-card-progress-bar'
                      size='sm'
                      value={programProgress?.completion}
                      width='100%'
                      css={{
                        '[role="progressbar"]': {
                          background: 'blue.500',
                        },
                        pointerEvents: 'none',
                      }}
                    />
                    <Text color='black' fontWeight='600' size='small-label'>
                      {t('common.percent', { val: programProgress?.completion })}
                    </Text>
                    {!isReadyForGraduation &&
                      !isConsumerSubscriptionPaused &&
                      programProgress?.complete &&
                      hasIncompleteRequiredAssessment &&
                      learningPlanAssessmentStepId && (
                        <ButtonLink
                          buttonProps={{
                            colorScheme: 'sea-foam',
                            width: '100%',
                            mt: '4px',
                            size: 'sm',
                            zIndex: 2,
                          }}
                          linkProps={{
                            href: `${publicEnv.NEXT_PUBLIC_LEARN_URL}/lp-step/${learningPlanAssessmentStepId}`,
                          }}
                        >
                          {t('learningPlan.startAssessment')}
                        </ButtonLink>
                      )}
                  </Stack>
                </Flex>
                {isReadyForGraduation && (
                  <Button
                    as='a'
                    colorScheme='sea-foam'
                    href={`${publicEnv.NEXT_PUBLIC_LEARN_URL}/graduation/${enrollment.key}`}
                    mt='8px'
                    size='sm'
                    w='full'
                    zIndex={2}
                  >
                    {t('program.graduateNow')}
                  </Button>
                )}
              </Flex>
            )}
          </Flex>
        </Flex>

        {!enrollment && !!enterpriseData && (
          <Box left='12px' pointerEvents='none' position='absolute' top='12px' zIndex={2}>
            <Tag
              backgroundColor={isEnrollable ? 'white' : 'gray.300'}
              borderRadius='4px'
              padding='4px 8px'
              variant='no-stroke'
            >
              {isEnrollable ? t('program.enrollNow') : t('program.requestAccess')}
            </Tag>
          </Box>
        )}

        {!!enrollment?.learningPlans?.length && (
          <Flex
            flexWrap='wrap'
            gap='8px'
            left='12px'
            pointerEvents='none'
            position='absolute'
            right='62px'
            top='12px'
            zIndex={1}
          >
            {enrollment.learningPlans.map((learningPlan) => (
              <Tag key={learningPlan.id} backgroundColor='#FFFFFFE5' variant='no-stroke'>
                {learningPlan.shortTitle}
              </Tag>
            ))}
          </Flex>
        )}

        {userId && clientRender && (
          <Box position='absolute' right='12px' top='12px' zIndex={2}>
            <FavoriteButton
              isTreatment={experiment.variant === 'variant_b'}
              programKey={hasCatalogItem ? props.catalogItem.key : props.basicItem.key}
            />
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box
      as='article'
      bg='blue.800'
      borderRadius='base'
      flex={props.grow ? { base: 'unset', xl: 1 } : 'unset'}
      flexShrink={0}
      height='292px'
      minWidth={props.minWidth}
      position='relative'
      role='group'
      transition='all 0.3s ease-in-out'
      width={props.width}
      _hover={{
        boxShadow: 'lg',
      }}
    >
      {!enrollment && !!enterpriseData && (
        <Box left='8px' padding='8px' position='absolute' top='8px' zIndex={2}>
          <Tag backgroundColor={isEnrollable ? 'white' : 'gray.300'}>
            {isEnrollable ? t('program.enrollNow') : t('program.requestAccess')}
          </Tag>
        </Box>
      )}

      {!!enrollment?.learningPlans?.length && (
        <Flex flexWrap='wrap' gap='8px' left='8px' position='absolute' right='68px' top='8px' zIndex={2}>
          {enrollment.learningPlans.map((learningPlan) => (
            <Tag key={learningPlan.id} variant='no-stroke'>
              {learningPlan.shortTitle}
            </Tag>
          ))}
        </Flex>
      )}

      {userId && clientRender && (
        <Box position='absolute' right='8px' top='8px' zIndex={2}>
          <FavoriteButton programKey={hasCatalogItem ? props.catalogItem.key : props.basicItem.key} />
        </Box>
      )}

      <Box
        borderRadius='base'
        bottom={0}
        height='100%'
        left={0}
        overflow='hidden'
        position='absolute'
        top={0}
        width='100%'
      >
        <Box
          bgGradient='linear(to-b, transparent, white, transparent)'
          bottom={0}
          height='120%'
          mixBlendMode='soft-light'
          opacity={0.3}
          pointerEvents='none'
          position='absolute'
          right={0}
          transform={`translateX(50%) translateY(100%) rotate(-20deg)`}
          transition='transform 0.3s ease-in-out'
          width='200%'
          zIndex={100}
          _groupHover={{
            transform: `translateX(10%) translateY(-10%) rotate(-20deg)`,
          }}
        />
      </Box>
      <Flex flexDirection='column' height='100%' position='relative' width='100%'>
        <Flex
          bg='blue.800'
          borderTopRadius='base'
          cursor='pointer'
          flex={1}
          padding='8px'
          onClick={legacyHandleCardClick}
          {...(focusStyles('blue.500') as any)}
        >
          <Box
            bgGradient='linear(to-t, blue.800, transparent)'
            bottom={0}
            height='140px'
            left={0}
            position='absolute'
            width='100%'
            zIndex={2}
          />
          {hasCatalogItem && props.catalogItem.image && typeof props.catalogItem.image === 'string' && (
            <Image
              alt=''
              priority={Boolean(props.priority)}
              quality={100}
              sizes='442px'
              src={props.catalogItem.image}
              style={{ objectFit: 'cover', borderRadius: '4px 4px 0 0' }}
              fill
            />
          )}
          {!props.catalogItem?.image && (
            <Image
              alt=''
              priority={Boolean(props.priority)}
              quality={100}
              sizes='442px'
              src={fallbackImage}
              style={{ objectFit: 'cover', borderRadius: '4px 4px 0 0' }}
              fill
            />
          )}
          <Box alignSelf='flex-end' position='relative' zIndex={2}>
            <Heading as='a' color='white' href={url} size='h5'>
              {hasCatalogItem ? props.catalogItem.title : props.basicItem.title}
            </Heading>
          </Box>
        </Flex>
        {!enrollment &&
          hasCatalogItem &&
          (hasReviews || props.catalogItem.duration || props.catalogItem.difficultyLevel) && (
            <Stack padding='8px' spacing='8px'>
              {hasReviews && (
                <Flex alignItems='center' gap='4px'>
                  <Rating rating={props.catalogItem.reviewSummary.starsAverage} size='sm' />
                  <Text color='white' size='caption'>
                    ({props.catalogItem.reviewSummary.numberOfReviews})
                  </Text>
                </Flex>
              )}
              <Flex>
                {props.catalogItem.duration && (
                  <Text color='white' position='relative' size='caption' textTransform='capitalize' zIndex={4}>
                    {props.catalogItem.duration}
                  </Text>
                )}
                {props.catalogItem.difficultyLevel && (
                  <Text color='white' position='relative' size='caption' zIndex={4}>
                    {props.catalogItem.duration && ', '}
                    {props.catalogItem.difficultyLevel}
                  </Text>
                )}
              </Flex>
            </Stack>
          )}
        {enrollment && (
          <Box padding='8px'>
            <Flex alignItems='flex-end' gap='8px' position='relative'>
              {!isReadyForGraduation && isConsumerSubscriptionPaused && (
                <>
                  <SubscriptionPausedModal
                    isOpen={isPausedModalOpen}
                    paymentDate={formattedResumeDate}
                    onClose={closePausedModal}
                  />
                  <PlayButton
                    ariaLabel={t('settings.subscription.resumeSubscription')!}
                    overrideColor='gray.500'
                    onClick={openPausedModal}
                  />
                </>
              )}
              {!isReadyForGraduation && !isConsumerSubscriptionPaused && (
                <PlayButton
                  href={`${enrollment.classroomLink}/resume-learning`}
                  ariaLabel={
                    t('program.continueProgram', {
                      programName: hasCatalogItem ? props.catalogItem.title : props.basicItem.title,
                    })!
                  }
                />
              )}
              <Stack alignItems='flex-start' flex={1} spacing='4px' width='100%'>
                {Boolean(programProgress?.completion) && (
                  <>
                    <Progress
                      aria-label={t('program.progressIs', { percent: programProgress?.completion ?? 0 })!}
                      bg='gray.700'
                      data-testid='catalog-card-progress-bar'
                      size='sm'
                      value={programProgress?.completion}
                      width='100%'
                      css={{
                        '[role="progressbar"]': {
                          background: '#00C5A1',
                        },
                      }}
                    />
                    <Text color='white' size='small-label'>
                      {t('common.percent', { val: programProgress?.completion })}
                    </Text>
                  </>
                )}

                {!isReadyForGraduation &&
                  !isConsumerSubscriptionPaused &&
                  programProgress?.complete &&
                  hasIncompleteRequiredAssessment &&
                  learningPlanAssessmentStepId && (
                    <ButtonLink
                      buttonProps={{
                        colorScheme: 'sea-foam',
                        width: '100%',
                        mt: '8px',
                      }}
                      linkProps={{
                        href: `${publicEnv.NEXT_PUBLIC_LEARN_URL}/lp-step/${learningPlanAssessmentStepId}`,
                      }}
                    >
                      {t('learningPlan.startAssessment')}
                    </ButtonLink>
                  )}
              </Stack>
            </Flex>
            {isReadyForGraduation && (
              <Button
                as='a'
                colorScheme='sea-foam'
                href={`${publicEnv.NEXT_PUBLIC_LEARN_URL}/graduation/${enrollment.key}`}
                mt='16px'
                w='full'
              >
                {t('program.graduateNow')}
              </Button>
            )}
          </Box>
        )}
      </Flex>
    </Box>
  );
}

export function CatalogCardSkeleton({ width = '190px' }: { width?: string | BreakpointValue<string> }) {
  return (
    <Flex
      bg='blue.800'
      borderRadius='base'
      flexDirection='column'
      flexShrink={0}
      height='292px'
      minWidth='152px'
      overflow='hidden'
      width={width}
    >
      <Stack flex={1} height='100%' justifyContent='flex-end' padding='8px' spacing='8px'>
        <Skeleton h='16px' />
        <Skeleton h='16px' />
      </Stack>
      <Stack padding='8px' spacing='8px'>
        <Rating isLoading={true} size='sm' />
        <Skeleton h='8px' width='60px' />
      </Stack>
    </Flex>
  );
}

export function isCatalogCardWithCatalogItem(
  catalogCardProps: CatalogCardWithCatalogItem | CatalogCardWithBasicItem
): catalogCardProps is CatalogCardWithCatalogItem {
  return catalogCardProps.catalogItem !== undefined;
}
